.page-wrapper {
  height: 100%;
}

.page-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color .2s ease-in-out;
}

.page-content {
  position: relative;
  z-index: 1;
  animation: fadeIn .8s ease-in-out;
  height: 100%;
  overflow-y: scroll;
}

.page-background {
  background-color: #173624;
}

.norda .page-background {
  background-color: #EEE7FF;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.App ::selection {
  background-color: #fff0db;
  color: #173624;
  -webkit-text-fill-color: #173624;
}

.norda .App ::selection {
  background-color: #7958CE;
  color: #EEE7FF;
  -webkit-text-fill-color: #EEE7FF;
}

.App {
  min-height: 100%;
  display: flex;
  gap: 3.5rem;
  flex-flow: column;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
}

.sparks {
  position: absolute;
  top: 15px;
  left: 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.arrowSvg {
  width: 14px;
  height: 14px;
  transform: rotate(-90deg);
  margin: 0 3px 0 13px;
}
.sparks span {
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #fff0db;
  pointer-events: none;
}
.sparks span::selection {
  background-color: transparent;
  color: #fff0db;
  -webkit-text-fill-color: #fff0db;
}
.sparksSvg {
  width: 35px;
  height: 35px;
  cursor: pointer;
  animation: zoom-in-zoom-out 4s ease infinite;
}

.norda .sparks span {
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #7958CE;
  pointer-events: none;
}
.norda .sparks span::selection {
  background-color: transparent;
  color: #7958CE;
  -webkit-text-fill-color: #7958CE;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  7% {
    transform: scale(1.5, 1.5);
  }
  14% {
    transform: scale(1, 1);
  }
}

h1 {
  font-family: 'TASA Orbiter';
  font-size: 7rem;
  margin: 0;
  font-weight: 900;
}
.norda h1 {
  font-size: 5rem;
}
/* h1:hover {
  background: -webkit-linear-gradient(0deg, #9D6A06, #910330, #5E0BBD, #00698E);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
p {
  font-family: 'Valverde';
  font-size: 2.4vw;
  margin: 0;
}
a {
  color: inherit;
  text-decoration: inherit;
  transition: all .1s ease-in-out;
  width: max-content;
}
a:hover {
  transform: skewX(-15deg);
}

.header {
  padding: 3rem 3rem 0 3rem;
  width: max-content;
  align-self: end;
  display: flex;
  flex-flow: column;
  gap: 1rem;
}
.content {
  display: flex;
  flex-flow: column;
  gap: 2rem;
  padding: 0 3rem 2.8rem;
}

.contactPlaces {
  font-family: 'DM Sans', sans-serif;
  font-weight: 800;
  text-align: end;
  display: flex;
  flex-flow: column;
  gap: .5rem;
  align-items: flex-end;
}

.link {
  text-decoration: underline;
  transition: transform .1s ease-in-out;
  display: inline-block;
}
.link:hover {
  transform: skewX(-15deg);
}

@media (max-width:600px) {
  h1 {
    font-size: 15vw;
  }
  .norda h1 {
    font-size: 12vw;
  }
  p {
    font-size: 6vw;
  }
  .header {
    padding: 3.5rem 1rem 0 1rem;
    gap: .35rem;
  }
  .content {
    padding: 0 1rem .8rem;
  }
  .contactPlaces {
    gap: .35rem;
  }
}
